import { lazy } from 'react';
import { ROUTE_PATHS } from '../contants/routePaths';

export const Login = lazy(() => import('../pages/login/Login'));
export const MobileChat = lazy(() => import('../pages/mobile-chat/MobileChat'));
export const SignUp = lazy(() => import('../pages/signup/SignUp'));
export const VerifyOtp = lazy(() => import('../pages/otp/VerifyOtp'));
export const ForgetPassword = lazy(() => import('../pages/forget-password/ForgetPassword'));
export const EnterEmail = lazy(() => import('../pages/forget-password/EnterEmail'));
export const Category = lazy(() => import('../pages/category/Category'));
export const Consultant = lazy(() => import('../pages/consultant/Consultant'));
export const Chat = lazy(() => import('../pages/chat/Chat'));
export const Notification = lazy(() => import('../pages/notifications/Notification'));
export const UserProfile = lazy(() => import('../pages/user-profile/UserProfile'));
export const RegisterConsultant = lazy(() => import('../pages/register-consultant/RegisterConsultant'));
export const FaqPage = lazy(() => import('../pages/faq/FAQ'));
export const PrivacyPolicy = lazy(() => import('../pages/privacy-policy/PrivacyPolicy'));
export const TermsAndConditions = lazy(() => import('../pages/terms-and-conditions/TermsAndConditions'));
export const MobileMarketing = lazy(() => import('../pages/mobile-marketing/MobileMarketing'));
export const PageNotFound = lazy(() => import('../pages/404/PageNotFound'));
export const PaymentSuccess = lazy(() => import('../pages/payment-success/PaymentSuccess'));
export const Logout = lazy(() => import('../pages/login/Logout'));
// export const Home = lazy(() => import('../pages/home/Home'));
export const ApiVersion = lazy(() => import('../pages/ApiVersion/ApiVersion'));
export const ConsultationRequest = lazy(() => import('../pages/consultationRequest/ConsultationRequest'));
export const ConsultationRequestSuccess = lazy(() => import('../pages/ConsultationRequestSuccess/ConsultationRequestSuccess'));
export const AboutFasrly = lazy(() => import('components/about-fasrly/AboutFasrly'));
export const ManageSchedule = lazy(() => import('components/profile-detail-cards/ManageSchedule/ManageSchedule'));
export const AppStore = lazy(() => import('../pages/AppStore/AppStore'));
export const TestWebview = lazy(() => import('../pages/TextWebview/TestWebview'));
export const OneLink = lazy(() => import('../pages/OneLink/OneLink'));
export const ConsultantWithUsername = lazy(() => import('../pages/consultantWithUsername/ConsultantWithUsername'));

export const {
  LOGIN,
  SIGNUP,
  HOME,
  CATEGORY,
  VERIFY_OTP,
  CONSULTANT,
  CONSULTANT_USERNAME,
  CHAT,
  NOTIFICATION,
  USER_PROFILE,
  REGISTER_CONSULTANT,
  FAQ_PAGE,
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS,
  MOBILE_MARKETING,
  PAGE_NOT_FOUND,
  PAYMENT_SUCCESS,
  LOGOUT,
  VERIFY_EMAIL,
  UPDATE_PASSWORD,
  HOME2,
  MOBILECHAT,
  API_PATH,
  ABOUT,
  CONSULTATION_REQUEST,
  CONSULTATION_REQUEST_SUCCESS,
  MANAGE_SCHEDULE,
  APP_STORE,
  TEST_WEBVIEW,
  ONE_LINK,
} = ROUTE_PATHS;
