export const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL ?? '';
export const REACT_APP_BASE_API_URL_V2 = process.env.REACT_APP_BASE_API_URL_V2 ?? '';
// export const REACT_APP_BASE_API_URL_V2 = 'http://localhost:8000/api-v2';

export const LOGIN_AUTH_KEY = process.env.REACT_APP_LOGIN_AUTH_KEY;
export const LOGIN_AUTH_CLIENT_ID = 6;
export const REACT_APP_CMS_URL = process.env.REACT_APP_CMS_URL ?? '';
export const REACT_APP_GTM_ID = process.env.REACT_APP_GTM_ID ?? '';
export const REACT_APP_SENDBIRD_API_TOKEN = process.env.REACT_APP_SENDBIRD_API_TOKEN ?? '';
export const REACT_APP_SENDBIRD_ID = process.env.REACT_APP_SENDBIRD_ID ?? '';
export const REACT_APP_FASRLY_URL = process.env.REACT_APP_FASRLY_URL ?? '';
export const REACT_APP_GOOGLE_REDIRECT_URI = process.env.REACT_APP_GOOGLE_REDIRECT_URI ?? '';
export const REACT_APP_COMMOM_REDIRECT_URI_EN = process.env.REACT_APP_COMMOM_REDIRECT_URI_EN ?? '';
export const REACT_APP_COMMOM_REDIRECT_URI_AR = process.env.REACT_APP_COMMOM_REDIRECT_URI_AR ?? '';
export const REACT_APP_ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID ?? '';
export const REACT_APP_ALGOLIA_API_KEY = process.env.REACT_APP_ALGOLIA_API_KEY ?? '';
export const REACT_APP_ASSETS_BASE_URL = process.env.REACT_APP_ASSETS_BASE_URL ?? 'https://d22lbpcxo85heg.cloudfront.net/assets/app/media';
export const REACT_APP_DAILY_CO_CALLING_URL = process.env.REACT_APP_DAILY_CO_CALLING_URL ?? 'https://fasrlychat.web.app';
export const REACT_APP_FIREBASE_MESSAGING_KEY = process.env.REACT_APP_FIREBASE_MESSAGING_KEY ?? '';

export const REACT_APP_GOOGLE_CLIENTID_NEW = process.env.REACT_APP_GOOGLE_CLIENTID_NEW ?? '';
export const REACT_APP_TWITTER_CLIENTID_NEW = process.env.REACT_APP_TWITTER_CLIENTID_NEW ?? '';
export const REACT_APP_TWITTER_SECRET_NEW = process.env.REACT_APP_TWITTER_SECRET_NEW ?? '';
export const REACT_APP_FACEBOOK_APPID_NEW = process.env.REACT_APP_FACEBOOK_APPID_NEW ?? '';
