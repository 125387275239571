import { iConsultationDetailOfConsultant } from './../../types/commonInterfaces';
import { iConsultant, iConsultant_v2, iPaginationMeta, iReviews } from '../../types/commonInterfaces';
import { createSlice } from '@reduxjs/toolkit';
import {
  getConsultantListThunk,
  getConsultantThunk,
  getConsultantDistinguishThunk,
  getConsultantReviewThunk,
  createConsultantThunk,
  getConsultantConsultationDetail,
  getConsultantThunkWithUsername,
} from './consultant-thunks';
import { iError } from '../../types/commonInterfaces';
export interface iConsultantStoreState {
  consultantList: iConsultant_v2[];
  consultantReviews: iReviews[];
  paginationMeta: iPaginationMeta;
  consultant: iConsultant_v2 | null;
  consultantDistinguish: iConsultant[];
  error: iError;
  success: iError;
  consultationDetailoFConsultant: iConsultationDetailOfConsultant[];
  loading: boolean;
}
const DEFAULT_ERROR_MESSAGE = 'Something went wrong';
const initialState: iConsultantStoreState = {
  consultantList: [],
  consultantReviews: [],
  consultant: null,
  consultantDistinguish: [],
  error: {} as iError,
  paginationMeta: {
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 1,
  },
  success: {} as iError,
  consultationDetailoFConsultant: [] as iConsultationDetailOfConsultant[],
  loading: false,
};

const consultantSlice = createSlice({
  name: 'consultant',
  initialState,
  reducers: {
    clearError(state) {
      state.error = {} as iError;
      state.success = {} as iError;
    },
  },
  extraReducers(builder) {
    builder.addCase(getConsultantListThunk.fulfilled, (state, action) => {
      state.consultantList = action.payload.consultants.length ? action.payload.consultants : [];
      state.paginationMeta = action.payload.meta;
    });
    builder.addCase(getConsultantThunk.fulfilled, (state, action) => {
      state.consultant = action.payload.consultants ?? null;
    });
    builder.addCase(getConsultantThunkWithUsername.fulfilled, (state, action) => {
      state.consultant = action.payload.consultants ?? null;
    });
    builder.addCase(getConsultantThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(getConsultantDistinguishThunk.fulfilled, (state, action) => {
      state.consultantDistinguish = action.payload.consultantDistinguish.length ? action.payload.consultantDistinguish : [];
    });
    builder.addCase(getConsultantDistinguishThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(getConsultantReviewThunk.fulfilled, (state, action) => {
      state.consultantReviews = action.payload.consultantReviews.length ? action.payload.consultantReviews : [];
      state.paginationMeta = action.payload.meta;
    });
    builder.addCase(getConsultantReviewThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(createConsultantThunk.fulfilled, (state, action) => {
      state.success = action.payload.success;
    });
    builder.addCase(createConsultantThunk.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
    });
    builder.addCase(getConsultantConsultationDetail.pending, (state, action) => {
      state.loading = true;
      state.error = {} as iError;
    });
    builder.addCase(getConsultantConsultationDetail.fulfilled, (state, action) => {
      state.consultationDetailoFConsultant = action.payload.consultantDetail;
      state.error = {} as iError;
      state.loading = false;
    });
    builder.addCase(getConsultantConsultationDetail.rejected, (state, action) => {
      state.error.status = true;
      state.error.message = action.payload?.message || DEFAULT_ERROR_MESSAGE;
      state.loading = false;
    });
  },
});
export const { clearError } = consultantSlice.actions;
export default consultantSlice.reducer;
