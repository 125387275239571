import { FC } from 'react';
import isEmpty from 'lodash/isEmpty';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import Home from 'pages/home/Home';
import { useAppSelector } from 'hooks/storeHooks';
import { getAccessTokenFromLS } from 'utils/storageUtils';
import { ROUTE_PATHS } from 'contants/routePaths';
import PrivateRoutes from './PrivateRoutes';
import NavBar from '../components/core-components/header/NavBar';
import MobileLayout from '../components/core-components/mobileLayout/MobileLayout';
import AuthLayout from '../layout/auth-layout/AuthLayout';
import {
  ABOUT,
  API_PATH,
  CATEGORY,
  Category,
  CHAT,
  Chat,
  CONSULTANT,
  Consultant,
  EnterEmail,
  FAQ_PAGE,
  FaqPage,
  ForgetPassword,
  HOME,
  HOME2,
  LOGIN,
  Login,
  LOGOUT,
  Logout,
  MOBILE_MARKETING,
  MOBILECHAT,
  MobileChat,
  MobileMarketing,
  NOTIFICATION,
  Notification,
  PAGE_NOT_FOUND,
  PageNotFound,
  PAYMENT_SUCCESS,
  PaymentSuccess,
  PRIVACY_POLICY,
  PrivacyPolicy,
  REGISTER_CONSULTANT,
  RegisterConsultant,
  SIGNUP,
  SignUp,
  TERMS_AND_CONDITIONS,
  TermsAndConditions,
  UPDATE_PASSWORD,
  USER_PROFILE,
  UserProfile,
  VERIFY_EMAIL,
  VERIFY_OTP,
  VerifyOtp,
  CONSULTATION_REQUEST,
  ConsultationRequest,
  CONSULTATION_REQUEST_SUCCESS,
  ConsultationRequestSuccess,
  AboutFasrly,
  ApiVersion,
  MANAGE_SCHEDULE,
  ManageSchedule,
  APP_STORE,
  AppStore,
  TEST_WEBVIEW,
  OneLink,
  ONE_LINK,
  CONSULTANT_USERNAME,
  ConsultantWithUsername,
} from './RouteLoaders';
import TestWebview from 'pages/TextWebview/TestWebview';

const Router: FC = () => {
  const userLang = useAppSelector((state) => state.content.userLang);
  const token = getAccessTokenFromLS();
  const auth = { token: isEmpty(token) ? false : true };

  const routes = [
    {
      element: <NavBar headerBorder headerClass="blueForeground" />,
      children: [
        {
          element: <PrivateRoutes />,
          children: [{ path: USER_PROFILE, element: <UserProfile /> }],
        },
      ],
    },
    {
      element: <NavBar headerBorder headerClass="blueForeground" />,
      children: [
        {
          element: <PrivateRoutes />,
          children: [{ path: CHAT, element: <Chat /> }],
        },
      ],
    },
    {
      element: <NavBar headerClass="white-menu" />,
      children: [
        {
          path: NOTIFICATION,
          element: (
            <AuthLayout mobileImg card={false}>
              <Notification />
            </AuthLayout>
          ),
        },
      ],
    },
    {
      element: <NavBar headerClass="blueForeground" />,
      children: [
        {
          path: HOME,
          element: <Home />,
        },
        {
          path: HOME2,
          element: <Home />,
        },
        {
          path: CATEGORY,
          element: <Category />,
        },
        {
          path: LOGIN,
          element: auth.token ? (
            <Navigate to={ROUTE_PATHS.HOME2.replace(':lang', userLang)} />
          ) : (
            <AuthLayout card={true}>
              <Login />
            </AuthLayout>
          ),
        },
        {
          path: SIGNUP,
          element: (
            <AuthLayout card={true}>
              <SignUp />
            </AuthLayout>
          ),
        },
        {
          path: VERIFY_OTP,
          element: (
            <AuthLayout card={true}>
              <VerifyOtp />
            </AuthLayout>
          ),
        },
        {
          path: UPDATE_PASSWORD,
          element: (
            <AuthLayout card={true}>
              <ForgetPassword />
            </AuthLayout>
          ),
        },
        {
          path: VERIFY_EMAIL,
          element: (
            <AuthLayout card={true}>
              <EnterEmail />
            </AuthLayout>
          ),
        },
        // {
        //   path: TEST_WEBVIEW,
        //   element: <TestWebview />,
        // },
      ],
    },
    {
      element: <NavBar headerBorder headerClass="blueForeground" />,
      children: [
        {
          path: CONSULTANT_USERNAME,
          element: <ConsultantWithUsername />,
        },
        {
          path: CONSULTANT,
          element: <Consultant />,
        },
        {
          path: MANAGE_SCHEDULE,
          element: <ManageSchedule />,
        },
        {
          path: CONSULTATION_REQUEST,
          element: <ConsultationRequest />,
        },
        {
          path: CONSULTATION_REQUEST_SUCCESS,
          element: <ConsultationRequestSuccess />,
        },
      ],
    },
    {
      element: <NavBar headerClass="white-menu gradientMenu" />,
      children: [
        {
          path: REGISTER_CONSULTANT,
          element: <RegisterConsultant />,
        },
        {
          path: FAQ_PAGE,
          element: <FaqPage />,
        },
        {
          path: PRIVACY_POLICY,
          element: <PrivacyPolicy />,
        },
        {
          path: TERMS_AND_CONDITIONS,
          element: <TermsAndConditions />,
        },
        {
          path: ABOUT,
          element: <AboutFasrly />,
        },
        {
          path: MOBILE_MARKETING,
          element: <MobileMarketing />,
        },
        {
          path: PAGE_NOT_FOUND,
          element: <PageNotFound />,
        },
        {
          path: PAYMENT_SUCCESS,
          element: <PaymentSuccess />,
        },
        {
          path: LOGOUT,
          element: <Logout />,
        },
      ],
    },
    {
      element: <MobileLayout />,
      children: [
        {
          path: MOBILECHAT,
          element: <MobileChat />,
        },
      ],
    },
    {
      path: API_PATH,
      element: <ApiVersion />,
    },
    {
      path: APP_STORE,
      element: <AppStore />,
    },
    {
      path: ONE_LINK,
      element: <OneLink />,
    },
  ];

  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
};

export default Router;
